<template>
  <div class="row">
    <div class="col-md-4">
      <div class="d-flex justify-content-center align-items-center image-container">
        <div style="height: 350px; width: 350px; position: relative">
          <img
            class="image"
            :src="[uploadedImageUrl == '' ? form.photo : uploadedImageUrl]"
            @load="onImageLoad()"
          />
        </div>
      </div>
    </div>

    <div class="col-md-8">
      <b-form
        @submit.stop.prevent="formOnsubmit"
        enctype="multipart/form-data"
      >
        <!-- Input Name -->
        <b-form-group
          id="input-group-name"
          label="Nama Obat dan Alkes:"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="form.name"
            placeholder="Nama Obat dan Alkes"
          >
          </b-form-input>
          <small class="text-danger">{{ error.name }}</small>
        </b-form-group>

        <!-- Input Generic Name -->
        <b-form-group id="input-group-generic-name">
          <label for="input-generic-name">Nama Umum: <em class="text-muted">opsional</em></label>
          <b-form-input
            id="input-generic-name"
            v-model="form.generic_name"
            placeholder="Nama Umum"
          >
          </b-form-input>
          <small class="text-danger">{{ error.generic_name }}</small>
        </b-form-group>

        <!-- Input Sales Price -->
        <b-form-group
          id="input-group-sales-price"
          label="Harga Jual:"
          label-for="input-sales-price"
        >
          <b-form-input
            id="input-sales-price"
            inputmode="numeric"
            v-model="formatedSalesPrice"
            placeholder="Harga Jual"
          >
          </b-form-input>
          <small class="text-danger">{{ error.sales_price }}</small>
        </b-form-group>

        <!-- Image -->
        <b-form-group id="input-group-image">
          <label for="input-image">Gambar: <em class="text-muted">opsional</em></label>
          <b-form-file
            v-model="form.photo"
            placeholder="Upload Gambar"
            drop-placeholder="Drop file here..."
            @change="onFileUpload"
          ></b-form-file>
          <small class="text-danger">{{ error.photo }}</small>
        </b-form-group>

        <!-- Input Category -->
        <b-form-group
          id="input-group-medicine-category"
          label="Kategori Obat dan Alkes:"
          label-for="input-medicine-category"
        >
          <treeselect
            v-model="form.medicine_category_id"
            :multiple="false"
            :options="medicine_categories"
          />
          <small class="text-danger">{{ error.medicine_category_id }}</small>
        </b-form-group>

        <!-- Input Category -->
        <b-form-group
          id="input-group-uom"
          label="Satuan:"
          label-for="input-uom"
        >
          <treeselect
            v-model="form.uom_id"
            :multiple="false"
            :options="uoms"
            placeholder="Pilih Satuan"
          />
         <small class="text-danger">{{ error.uom_id }}</small>
        </b-form-group>

        <!-- Input Effect -->
        <b-form-group id="input-group-effect">
          <label for="input-effect">Efek: <em class="text-muted">opsional</em></label>
          <b-form-input
            id="input-effect"
            v-model="form.effects"
            placeholder="Efek"
          >
          </b-form-input>
          <small class="text-danger">{{ error.effects }}</small>
        </b-form-group>

        <!-- Input Minimum Stock -->
        <b-form-group id="input-group-minimum-stock">
          <label for="input-minimum-stock">Minimum Stok: <em class="text-muted">opsional</em></label>
          <b-form-input
            id="input-minimum-stock"
            v-model="form.minimum_stock"
            placeholder="Minimum Stok"
          >
          </b-form-input>
          <small class="text-danger">{{ error.minimum_stock }}</small>
        </b-form-group>

        <!-- Input Weight
        <b-form-group id="input-group-weight">
          <label for="input-weight">Berat (Kg): </label>
          <b-form-input
            id="input-weight"
            v-model="form.weight"
            placeholder="Berat"
          >
          </b-form-input>
          <small class="text-danger">{{ error.weight }}</small>
        </b-form-group> -->

        <!-- Input Long -->
        <!-- <b-form-group id="input-group-weight">
          <label for="input-weight">Dimensi (Cm): <em class="text-muted">opsional</em></label>
          <div class="row">
            <div class="col-md-4">
              <b-form-input
                id="input-long"
                v-model="form.long"
                placeholder="Panjang"
              >
              </b-form-input>
              <small class="text-danger">{{ error.long }}</small>
            </div>
            <div class="col-md-4">
              <b-form-input
                id="input-wide"
                v-model="form.wide"
                placeholder="Lebar"
              >
              </b-form-input>
              <small class="text-danger">{{ error.wide }}</small>
            </div>
            <div class="col-md-4">
              <b-form-input
                id="input-high"
                v-model="form.high"
                placeholder="Tinggi"
              >
              </b-form-input>
              <small class="text-danger">{{ error.high }}</small>
            </div>
          </div>
        </b-form-group> -->

        <!-- Input is_ecommerce_product -->
        <!-- <b-form-group>
          <b-form-checkbox
            id="input-is-ecommerce-product"
            v-model="form.is_ecommerce_product"
            switch
          >
            Tampilkan di E-Commerce <b>({{ form.is_ecommerce_product ? "Ya" : "Tidak" }})</b>
          </b-form-checkbox>
        </b-form-group> -->

        <!-- Input Description -->
        <b-form-group id="input-group-notes">
          <label for="input-notes">Catatan: <em class="text-muted">opsional</em></label>
          <b-form-textarea
            id="input-notes"
            v-model="form.notes"
            placeholder="Catatan"
            rows="4"
            max-rows="6"
          ></b-form-textarea>
          <small class="text-danger">{{ error.notes }}</small>
        </b-form-group>

        <!-- <div class="row">
          <div class="col-12 mt-3 mb-3">
            <span class="text-info"><i class="fas fa-info-circle mr-1 text-info"></i> Tips</span>
            <ul class="ml-6">
             
              <li class="mt-2">
                <span>Berat Barang akan digunakan pada jasa pengiriman apotek online</span>
              </li>
             
            </ul>
          </div>
        
        </div> -->

        <!-- Button -->
        <b-button
          type="submit"
          variant="primary"
        >Simpan</b-button>
        <b-button
          class="ml-2"
          variant="default"
          @click="$router.push('/medicine/list')"
        >
          Batal
        </b-button>
      </b-form>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "Add",

  props: {
    form: Object,
    route: String,
  },

  components: {
    Card,
  },

  data() {
    return {
      medicine_categories: [],
      uoms: [],
      // vendors: [],
      // Error
      error: {
        name: "",
        generic_name: "",
        photo: "",
        sales_price: "",
        medicine_category_id: "",
        uom_id: "",
        effects: "",
        notes: "",
        is_ecommerce_product: "",
        weight: "",
        high: "",
        wide: "",
        long: "",
        // vendor_id: null,
        // vendor_name: "",
      },
      imgLoaded: false,
      uploadedImageUrl: "",
    };
  },

  computed: {
    formatedSalesPrice: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ''), 10)
        if (!Number.isNaN(newValue)) {
          this.form.sales_price = newValue.toLocaleString('id-ID')
        } else {
          this.form.sales_price = ''
        }
      },
      get() {
        return this.form.sales_price
      }
    }
  },

  methods: {
    onImageLoad() {
      this.imgLoaded = true;
    },

    onFileUpload(evt) {
      this.uploadedImageUrl = URL.createObjectURL(evt.target.files[0]);
      console.log(evt);
      this.form.photo = evt.target.files[0];
      console.log("photo", this.form.photo);
    },

    async getMedicineCategoryOption() {
      let response = await module.setTreeSelect("medicine-categories");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.medicine_categories = response.data;
        this.medicine_categories.unshift({
          label: "Pilih Kategori Obat atau Alkes",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getUomOption() {
      let response = await module.setTreeSelect("uoms");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.uoms = response.data;
        this.uoms.unshift({
          label: "Pilih Satuan",
          id: "",
          isDisabled: true,
        });
      }
    },
    // async getVendorOption() {
    //   let response = await module.setTreeSelect("vendors");
    //   // Success
    //   if (response.state == "success") {
    //     // Set Option Select
    //     this.vendors = response.data;
    //   }
    // },

    async formOnsubmit() {
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        if (key === "is_ecommerce_product") {
          formData.append(key, value ? 1 : 0);
        } else {
          formData.append(key, value);
        }
      }
      // Make Request
      let response = await module.submit(formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/medicine/list");
      }
    },
  },

  mounted() {
    this.getMedicineCategoryOption();
    this.getUomOption();
    // this.getVendorOption();
  },
};
</script>

<style>
</style>