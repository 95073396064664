<template>
  <div>

    <!-- Main Row -->
    <div class="row">

      <!-- Form -->
      <div class="col-md-12">
        <Card v-bind:title="'Edit Obat dan Alkes'">
          <template
            v-slot:body
            v-if="dataLoaded"
          >
            <Form
              :form="form"
              :route="'medicines/' + form.id"
            />
          </template>
        </Card>
      </div>

    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Form from "@/component/medicine/MedicineForm.vue";
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Form
  },

  data() {
    return {
      // Form
      form: {
        name: "",
        generic_name: "",
        photo: "",
        // photo: null,
        sales_price: '',
        medicine_category_id: null,
        medicine_category_name: "",
        effects: "",
        notes: "",
        // vendor_id: null,
        // vendor_name: "",
      },
      // Other
      dataLoaded: false
    }
  },

  methods: {

    async get() {
      this.form = await module.get('medicines/' + this.$route.params.id)
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push('/medicine/list')
      } else {
        this.form['_method'] = 'put'
        if(this.form.is_ecommerce_product == 1){
          this.form.is_ecommerce_product = true
        }else if(this.form.is_ecommerce_product == 0){
          this.form.is_ecommerce_product = false
        }
        this.form.sales_price = parseInt(this.form.sales_price).toLocaleString('id-ID')
        this.dataLoaded = true
      }
    }

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Daftar Obat", route: "/medicine/list" },
      { title: "Edit Obat" },
    ])
    // Get Data
    this.get()
  },

}

</script>